var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBLDETAIL" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.map,
                      mappingType: _vm.mappingType,
                      label: "LBLSAVEALL",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.save,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    required: true,
                    label: "지도명",
                    name: "mapName",
                  },
                  model: {
                    value: _vm.map.mapName,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "mapName", $$v)
                    },
                    expression: "map.mapName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.map.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "plantCd", $$v)
                    },
                    expression: "map.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    required: true,
                    label: "LBLSORTORDER",
                    name: "sortOrder",
                  },
                  model: {
                    value: _vm.map.sortOrder,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "sortOrder", $$v)
                    },
                    expression: "map.sortOrder",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-radio", {
                  attrs: {
                    editable: _vm.editable,
                    comboItems: _vm.useFlagItems,
                    label: "LBLUSEFLAG",
                    name: "useFlag",
                  },
                  model: {
                    value: _vm.map.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "useFlag", $$v)
                    },
                    expression: "map.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
          [
            _c("c-upload", {
              staticStyle: { "margin-top": "0 !important" },
              attrs: {
                attachInfo: _vm.attachInfo,
                editable: _vm.editable,
                uploaderShow: _vm.uploaderShow,
              },
              on: { files: _vm.setMap },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: {
                  title: "지도",
                  noMarginPadding: true,
                  height: _vm.imgHeight,
                },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { ref: "parent", attrs: { id: "parent" } }, [
                      _vm.map.mapProcesses &&
                      _vm.map.mapProcesses.length > 0 &&
                      _vm.setComplete
                        ? _c(
                            "div",
                            { style: { height: _vm.imgHeight } },
                            _vm._l(_vm.map.mapProcesses, function (mp, idx) {
                              return _c(
                                "VueDraggableResizable",
                                {
                                  key: idx,
                                  ref: "markImage",
                                  refInFor: true,
                                  staticClass: "my-class",
                                  attrs: {
                                    parent: true,
                                    draggable: _vm.editable,
                                    resizable: _vm.editable,
                                    "class-name-dragging": "my-dragging-class",
                                    "class-name-active": "my-active-class",
                                    x: mp.x,
                                    y: mp.y,
                                    w: mp.w,
                                    h: mp.h,
                                    grid: [5, 5],
                                    minHeight: 20,
                                    minWidth: 20,
                                  },
                                  on: {
                                    dragging: (x, y) => _vm.onDrag(mp, x, y),
                                    resizing: (x, y, w, h) =>
                                      _vm.onResize(mp, x, y, w, h),
                                  },
                                },
                                [
                                  _c(
                                    "q-tooltip",
                                    {
                                      attrs: {
                                        anchor: "top middle",
                                        self: "center middle",
                                      },
                                    },
                                    [_vm._v(_vm._s(mp.processName))]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }